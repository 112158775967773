import "./App.css";

import { DynamicContextProvider, FilterAndSortWallets } from "@dynamic-labs/sdk-react";
import Home from "./components/Home";

function App() {
  return (
    <div className="App">
      <DynamicContextProvider
        settings={{
          newToWeb3WalletChainMap: {  
          primary_chain: 'evm',  
          wallets:{ evm: 'keyringpro' },
       }, 
          walletsFilter: FilterAndSortWallets(['keyringpro']),
          environmentId: process.env.REACT_APP_DYNAMIC_PROJECT_ID || ""    
           
        }}
      >
        <Home />
      </DynamicContextProvider>
    </div>
  );
}

export default App;
